import React, { useEffect, useMemo, useState } from "react";
import ImageCard from "../Card";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import games from "../../data/games";

function SelectedGames({ filter }) {
  const [gamesList, setGamesList] = useState([]);

  useEffect(() => {
    if (typeof filter === "number") {
      const newList = games.slice(0, filter);
      setGamesList(newList);
    }
    if (!filter)
      setGamesList(games.filter((item) => item.title === "Merge Fruit" || item.title === "Pou" ))
  }, [filter]);

  return (
    <section>
      <Typography
        variant="h4"
        sx={{
            mt: '2em',
          fontWeight: 700,
          textTransform: "capitalize",
          textAlign: "center",
          color: "#111111",
        }}
      >
        Selected Games
      </Typography>

      <Typography
        sx={{
            p: '2em 1em 0',
          textAlign: "center",
          color: "#111111",
        }}
      >
        These games have not only captured the hearts of our dedicated community but have also received their enthusiastic stamp of approval, assuring you of countless hours filled with fun and excitement. As you delve into these top selections, you'll discover the unique qualities that have elevated them to the status of favorites among our users.
<br/><br/>
Take your time to explore each game's captivating features, from their engaging storylines, impressive graphics, and challenging gameplay to their immersive soundtracks and innovative mechanics. These elements come together to create a gaming experience that's both enjoyable and memorable.
      </Typography>
      <Box
        sx={{
          width: "90%",
          maxWidth: "1150px",
          m: "1.5em auto",
          p: "1.5em 0",
          textAlign: "center",
          boxSizing: "border-box",
        }}
      >
        {gamesList?.map((item, index) => (
            <Box sx={{display: 'inline-block'}}>
            <ImageCard
                key={item.title + index}
                img={item.img}
                title={item.title}
                src={item.url}
                width="350px"
                height="350px"
            />
            </Box>
        ))}
      </Box>
    </section>
  );
}

export default SelectedGames;