import React from "react";
import { Box, Typography } from "@mui/material";

function TextSection() {
  return (
    <section id="text">
      <Box sx={{ width: "100%", m: "2em auto 0", textAlign: "center" }}>
        <Typography variant="h3" sx={{ fontWeight: 700, m: "0 auto", maxWidth: '80%' }}>
        Free Online Pokies<br/> The Hub for Free Online Gaming
        </Typography>
      </Box>
      <Box sx={{ width: "100%", m: "0 auto 2em", textAlign: "left" }}>
        <Typography
          component="p"
          sx={{ m: "0 auto", p: "1em 0 2em", fontSize: "18px", maxWidth: "80%" }}
        >
          Welcome to Free Online Pokies, where your free online gaming journey begins, blending excitement with the chance to win awesome prizes!
<br />
          <br />
At Free Online Pokies, we embrace the idea that the finest things in life, especially gaming, should be free. Our platform offers an extensive range of free online games, providing not just entertainment but also opportunities to win. Here, every game caters to various passions - be it for thrill, challenge, or glory, we have the perfect game for you.
<br />
          <br />
Our collection spans all genres, ensuring there's something for everyone. Dive into strategic gameplay with our strategy games, experience adrenaline-pumping action adventures, relive classic moments with arcade games, or engage in thought-provoking puzzles. The best part? All these games are completely free - no hidden charges, no premium models, just pure gaming pleasure.
<br />
          <br />
The heart of our platform is the 'Selected Games' section, a compilation of the finest games as chosen by our most esteemed critics: our users. These games have risen to popularity due to their captivating gameplay, impressive visuals, and their ability to keep players engaged.

          <br />
          <br />
Featured Games Include:

<br/><b>Epic Quest:</b> Embark on a mythical journey in this captivating RPG.
<br/><b>Puzzle Paradise:</b> Test your intellect and conquer the puzzle realm.
<br/><b>Speed Racer:</b> Satisfy your speed cravings in this ultimate racing challenge.
<br/><b>Galactic Defender:</b> Embark on a mission to save the universe in this thrilling space shooter.
<br/><b>Word Wizard:</b> Spellbind your way to triumph in this magical word game.
<br />
          <br />
Every game you play brings you closer to topping our leaderboards, earning you both acclaim and rewards. Player ratings play a crucial role in shaping our Selected Games, ensuring that the best games, as chosen by you, get the spotlight they deserve.
<br />
          <br />
Safety and fairness are paramount at Free Online Pokies. We are dedicated to providing a secure environment with advanced digital encryption and privacy protection for your data. Fair play is promoted through our strict code of conduct, ensuring an equitable experience for all players and games.
        </Typography>
      </Box>
    </section>
  );
}

export default TextSection;
