import React from "react";
import DocumentMeta from "react-document-meta";
import { Box, Typography } from "@mui/material";
import FeaturedGames from "../components/contentSections/FeaturedGames";

const meta = {
  title: "All Games | Free Online Pokies – your ultimate destination for free online gaming   Free Online Games",
  description: "Welcome to the world of Free Online Pokies - where every game is an adventure, and every adventure brings you a step closer to victory.",
};

function Games() {
  return (
    <DocumentMeta {...meta}>
      <Box sx={{mt: '2em'}}>
        <Typography variant="h1" sx={{mb: '.5em', textAlign: 'center', color: '#fff'}}>
          All games
        </Typography>
      <FeaturedGames />
      </Box>
    </DocumentMeta>
  );
}

export default Games;
