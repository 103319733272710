import React from "react";
import DocumentMeta from "react-document-meta";
import DisclaimerSection from "../components/contentSections/Disclaimer";

const meta = {
  title: "Disclaimer | Free Online Pokies – your ultimate destination for free online gaming   Free Online Games",
  description: "Welcome to our social pokies platform. Before you embark on your journey within our site and engage with any of the games, collectively referred to as the Services, we kindly request that you review and consent to the terms and conditions outlined below:",
};

function Disclaimer() {
  return (
    <DocumentMeta {...meta}>
      <DisclaimerSection />
    </DocumentMeta>
  );
}

export default Disclaimer;
