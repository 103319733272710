import React from "react";
import { Box, Typography } from "@mui/material";

function About() {
  return (
    <section id="about">
      <Box sx={{ width: "100%", m: "1em auto", textAlign: "center" }}>
        <Typography variant="h1" sx={{ m: "0 auto", fontWeight: 700 }}>
          About us
        </Typography>
      </Box>
      <Box sx={{ width: "100%", m: "1em auto", textAlign: "left" }}>
        <Typography
          component="p"
          sx={{
            m: "0 auto",
            p: "2em 0",
            fontSize: "18px",
            width: "80%",
          }}
        >
          Welcome to Free Online Pokies, a world where each game is not just a pastime
          but an exciting adventure leading to victory.
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
            Our Story
          </Typography>
          Free Online Pokies originated from a deep-seated passion for gaming and the
          belief that happiness shouldn't come with a cost. Our founding
          principle was simple yet profound: to establish a haven for gaming
          enthusiasts to Free Online Pokies freely. Starting as a small venture, we've
          evolved into a thriving epicenter for online gaming excellence.
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
            Our Motivation
          </Typography>
          Our team, comprised of passionate gamers, skilled designers, and
          visionary developers, is driven by the excitement of a great game and
          the joy it adds to life. We're united in our mission to deliver the
          most captivating gaming experiences online, guided by three core
          values:
          <br />
          <br />
          <b>The Joy of Gaming:</b> We're committed to making gaming enjoyable,
          accessible, and rewarding. By offering our games for free, we ensure
          everyone can partake in the excitement.
          <br />
          <b>The Spirit of Community:</b> Games are more than solitary activities; they
          foster community. We strive to create a space where players can
          connect, challenge each other, and share their gaming zeal.
          <br />
          <b>Fair Play and Integrity:</b> We uphold a fair, transparent, and secure
          gaming environment, emphasizing honesty and integrity in all our
          interactions and games.
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
            Our Game Collection
          </Typography>
          We're proud of our diverse game portfolio, carefully selected for its
          quality and joy-inducing potential. We regularly refresh our
          collection with new titles, chosen based on player feedback and
          popularity, ensuring a dynamic and enjoyable gaming environment.
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
            Innovation and Technology
          </Typography>
          Innovation is central to everything we do. We leverage cutting-edge
          technology to guarantee a seamless, immersive gaming experience. Our
          platform is user-friendly and intuitive, allowing you to dive into
          games effortlessly.
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
            Our Commitment to Excellence
          </Typography>
          Our team is wholly devoted to crafting the ultimate gaming
          environment. From game development to customer support, we tirelessly
          work to ensure every aspect of Free Online Pokies meets our high standards.
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
            Join Our Gaming Odyssey
          </Typography>
          We invite you to join us as we continue to explore the limitless world
          of online gaming. At Free Online Pokies, every game is a chance to discover
          new realms, connect with fellow gamers, and achieve triumph.
          <br />
          <br />
          With each game, you're not just playing; you're embarking on an
          exciting quest for fun, camaraderie, and victory. Let's play, win, and
          celebrate the essence of gaming together!
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
            Thank You for Choosing Free Online Pokies
          </Typography>
          We're thrilled to have you and look forward to delivering countless
          hours of free gaming enjoyment.
        </Typography>
      </Box>
    </section>
  );
}

export default About;
