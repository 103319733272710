import React from 'react'

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";

import discImg from "../../theme/img/webp/21.webp";

function AgeLabel() {
  return (
    <Snackbar open={true} autoHideDuration={6000}>
    <Alert
      severity="info"
      icon={false}
      sx={{position: 'fixed', bottom: '66px',
        background: "rgba(0, 0, 0, 0.5)",
        fontWeight: 700,
        p: "0 0.5em"
      }}
    >
      <Typography component="span" sx={{ fontSize: '16px', m: "0 0.5em", color: "#fff", lineHeight: 1 }}>
        No Real Money
      </Typography>
    </Alert>
  </Snackbar>
  )
}

export default AgeLabel