import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Typography } from "@mui/material";

function Footer() {
  const navItems = [
    { title: "Home", src: "/" },
    { title: "About", src: "/about" },
    { title: "Disclaimer", src: "/disclaimer" },
    { title: "Privacy Policy", src: "/privacy-policy" },
  ];
  const copyrightText = 'freeonline-pokies.com © 2023 All rights reserved.';

  return (
    <footer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column-reverse',
          justifyContent: 'center',
          alignItems: 'center',
          p: '0.5em 2em 2em',
        }}
      >
        <Typography sx={{m: '1.5em'}}>
          {copyrightText}
        </Typography>
        <List sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }  }}>
          {navItems.map((item) => (
            <ListItem key={item?.title} disablePadding>
              <Link to={item?.src} className="nav-link" relative="path">
                <Typography
                  sx={{
                    textDecoration: "none",
                    textTransform: "uppercase",
                    minWidth: "150px"
                  }}
                >
                  {item?.title}
                </Typography>
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
    </footer>
  );
}

export default Footer;
