import React from "react";
import DocumentMeta from "react-document-meta";
import AboutSection from '../components/contentSections/About';
import ContactText from "../components/contentSections/ContactText";

const meta = {
  title: "About | Free Online Pokies – your ultimate destination for free online gaming   Free Online Games",
  description: "Born out of a passion for gaming and the belief that joy should be without a price tag, Free Online Pokies was established with a simple yet powerful vision: to create a space where gaming enthusiasts can come together to Free Online Pokies, all for free. ",
};

function About() {
  return (
    <DocumentMeta {...meta}>
      <AboutSection />
      <ContactText />
    </DocumentMeta>
  );
}

export default About;
