import React from "react";
import DocumentMeta from "react-document-meta";
import GamesBlock from "../components/contentSections/GamesBlock";
import SelectedGames from "../components/contentSections/SelectedGames";
import TextSection from "../components/contentSections/TextSection";
import Hero from "../components/contentSections/Hero";

const meta = {
  title: "Home | Free Online Pokies – your ultimate destination for free online gaming Free Online Games",
  description: "At Free Online Pokies, we believe that the best things in life are free, and when it comes to gaming, we hold that truth to be self-evident.",
  canonical: "https://freeonline-pokies.com/",
  meta: {
    charset: "utf-8",
  },
};

function Main() {
  return (
    <DocumentMeta {...meta}>
      <Hero />
      <GamesBlock />
      <SelectedGames />
      <TextSection />
    </DocumentMeta>
  );
}

export default Main;
