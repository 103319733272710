import React from 'react'
import DocumentMeta from "react-document-meta";
import PolicyText from '../components/contentSections/Policy';

const meta = {
    title: "Privacy policy | Free Online Pokies – your ultimate destination for free online gaming   Free Online Games",
    description: "By using our Services, you agree to the practices described in this Policy. If you do not agree to this Policy, please do not use our Services.",
  };


function Policy() {
  return (
    <DocumentMeta {...meta}>
        <PolicyText />
    </DocumentMeta>
  )
}

export default Policy