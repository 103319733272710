import React from "react";
import { Box, Typography } from "@mui/material";

function Hero() {
  return (
    <section className="hero">
      <Box
        sx={{
          p: "1em 3em 5em",
          borderRadius: "1em",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          color: "#111",
        }}
        variant="outlined"
      >
        <Box
          sx={{
            m: "1em auto 0",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              display: "inline-block",
              ml: "1em",
              verticalAlign: "middle",
              textAlign: "center",
            }}
          >
            Free Online Games
          </Typography>
          <Typography
          component="p"
          sx={{
            m: "0 auto",
            pt: "2em",
            fontSize: "18px",
            textAlign: "left",
          }}
        >Welcome to Free Online Pokies, a world where each game is not just a pastime but an exciting adventure leading to victory.
          We're proud of our diverse game portfolio, carefully selected for its quality and joy-inducing potential. We regularly refresh our collection with new titles, chosen based on player feedback and popularity, ensuring a dynamic and enjoyable gaming environment.
        </Typography>
        </Box>
      </Box>
    </section>
  );
}

export default Hero;
