import { Box, Typography } from "@mui/material";
import React from "react";

function ContactText() {
  return (
    <section id="contact">
    <Box
      sx={{
        m: "2em auto 5em",
        textAlign: "center",
      }}
    >
      <Typography variant="h3">Contact Us</Typography>
      
      <Typography
          component="p"
          sx={{ m: "0 auto", pt: "2em", fontSize: "18px", maxWidth: "1000px", textAlign: 'left' }}
        >
          
<b>Email:</b> contact@freeonline-pokies.com
<br/><b>Phone:</b> +61 2 6215 9184
<br/><br/>
Our dedicated support team is ready to assist you with any inquiries or feedback you may have. We strive to respond to all queries promptly because we value your time and gaming experience.
<br/><br/>
For partnership inquiries, please reach out to partnerships@freeonline-pokies.com.


      </Typography>
    </Box>

    </section>
  )
}

export default ContactText